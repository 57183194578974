import React, { useEffect, useState } from 'react';
import '../styles/index.css';

function Index() {
  const [uid, setUID] = useState(null);
  useEffect(() => {
    async function getUID() {
      const res = await fetch('/api/uid');
      const newUID = await res.text();
      setUID(newUID);
    }
    getUID();
  }, []);

  return (
    <div class="onepixel">1</div>
  );
}

export default Index;
